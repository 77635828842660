<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-if="carregando"
        cols="12"
      >
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </v-col>

      <v-col
        v-for="(m, idx) in meses "
        :key="idx"
        cols="12"
        md="3"
        class="pb-0"
      >
        <v-sheet color="grey lighten-2">
          <h3 class="text-center">
            {{ meses[idx].split('-')[1] }}/{{ meses[idx].split('-')[0] }}
          </h3>
          <v-calendar
            :start="m"
            type="month"
          >
            <template v-slot:day-label="{ date, month }">
              <template v-if="month == meses[idx].split('-')[1]">
                <v-row
                  class="fill-height"
                  :class="{ 'blue': diasComPassagens.includes(date) }"
                >
                  <v-col>
                    <span class="caption">
                      {{ dia(date) }}
                    </span>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                {{ '' }}
              </template>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import perfilVeiculoApi from '@/api/perfil-veiculo'
  import dayjs from 'dayjs'

  export default {

    components: {

    },

    props: {
      placa: {
        type: String,
        default: '',
      },
    },

    data () {
      return {
        meses: [],
        carregando: false,
        diasComPassagens: [],
      }
    },

    mounted () {
      const meses = []
      const date = new Date()
      date.setDate(1)
      for (var i = 0; i < 12; i++) {
        meses.push(dayjs(date).format('YYYY-MM-DD'))
        date.setMonth(date.getMonth() - 1)
      }
      this.meses = meses

      this.buscar()
    },

    methods: {
      async buscar () {
        try {
          this.carregando = true
          const resposta = await perfilVeiculoApi.calendario(this.placa)

          resposta.data.forEach((diaPassagem) => {
            this.diasComPassagens.push(diaPassagem.data)
          })
        } catch (e) {

        } finally {
          this.carregando = false
        }
      },

      nomeMes (dtStr) {
        const date = new Date(dtStr)
        if (date.toString() !== 'Invalid Date') { return date.toLocaleString('pt-br', { month: 'long' }) } else { return 'dezembro' }
      },

      dia (dtStr) {
        return dayjs(dtStr).format('DD')
      },

    },

  }
</script>
