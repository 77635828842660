import axios from '@/plugins/axios'

export default {

  async pontosCaptura (placa) {
    return await axios.get(`perfil-veiculo/pontos-captura/${placa}`)
  },

  async historico (placa) {
    return await axios.get(`perfil-veiculo/historico/${placa}`)
  },

  async pontosEntrada (placa) {
    return await axios.get(`perfil-veiculo/pontos-entrada/${placa}`)
  },

  async pontosSaida (placa) {
    return await axios.get(`perfil-veiculo/pontos-saida/${placa}`)
  },

  async passagensPorDiaDaSemana (placa) {
    return await axios.get(`perfil-veiculo/passagens-dia-semana/${placa}`)
  },

  async frequenciaHorarioEntrada (placa) {
    return await axios.get(`perfil-veiculo/frequencia-horario-entrada/${placa}`)
  },

  async frequenciaHorarioSaida (placa) {
    return await axios.get(`perfil-veiculo/frequencia-horario-saida/${placa}`)
  },

  async calendario (placa) {
    return await axios.get(`perfil-veiculo/calendario/${placa}`)
  },

}
